<template>
  <div class="container">
    <div class="column form-wrap is-4">
      <div class="box" id="sign-up">
        <div class="cst-logo m-4 has-text-centered">
          <img
            src="../../assets/logo.png"
            style="height: 36px"
            alt="iulaanu-logo"
          />
        </div>
        <b-field label="" message="This cannot be changed once created.">
          <b-input
            v-model="name"
            placeholder="Employer Name"
            :readonly="isLoading"
          ></b-input>
        </b-field>
        <div class="field">
          <div class="control is-expanded">
            <div class="select is-fullwidth">
              <select v-model="sector">
                <option :value="null" disabled selected>
                  Select your sector
                </option>
                <option
                  v-for="sector in sectorOptions"
                  :key="sector.id"
                  :value="sector.id"
                >
                  {{ sector.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <b-field label="">
          <b-input
            v-model="reg_no"
            placeholder="Reg No"
            :readonly="isLoading"
          ></b-input>
        </b-field>
        <b-field label="">
          <b-input
            v-model="email"
            placeholder="Company Email"
            :readonly="isLoading"
          ></b-input>
        </b-field>
        <b-field label="">
          <b-input
            v-model="phone"
            placeholder="Contact No"
            :readonly="isLoading"
          ></b-input>
        </b-field>
        <div class="field">
          <label class="label">How did you find us?</label>
          <div class="control is-expanded">
            <div class="select is-fullwidth">
              <select v-model="reference">
                <option :value="null" disabled selected>
                  Select your option
                </option>
                <option
                  v-for="method in referenceOptions"
                  :key="method.id"
                  :value="method.id"
                >
                  {{ method.name }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="mt-1 has-text-left">
          <small> Our team will check and approve your employer account.</small>
        </div>
        <div class="field mt-5">
          <div class="control">
            <b-button
              type="is-primary is-fullwidth mb-3"
              @click="submitForm"
              :loading="isLoading"
            >
              Create Employer</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Login",
  data() {
    return {
      name: "",
      reg_no: "",
      email: "",
      phone: "",
      sector: null,
      reference: null,
      referenceOptions: [],
      sectorOptions: [],
      url: "/api/employers/",
      error: false,
      errorMessage: "",
      redirect: this.$route.query.redirect,
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters.isLoading;
    },
  },
  methods: {
    async getReferenceOptions() {
      await axios.get("/api/referencemethods/").then((response) => {
        this.referenceOptions = response.data;
      });
    },
    async getSectorOptions() {
      await axios.get("/api/sectors/").then((response) => {
        this.sectorOptions = response.data;
      });
    },
    async performCreate() {
      let formData = new FormData();
      formData.append("name", this.name);
      formData.append("reg_no", this.reg_no);
      formData.append("email", this.email);
      formData.append("phone", this.phone);
      formData.append("reference_method", this.reference);
      formData.append("sector", this.sector);

      await axios
        .post(this.url, formData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 201) {
            // update current employer
            this.$store.dispatch("employers/updateEmployer", response.data.id);

            // toast open
            this.$buefy.toast.open({
              message:
                "Successfully created employer account. Please complete your profile to enable job posting.",
              type: "is-success",
              queue: false,
              duration: 5000,
            });
          } else {
            this.error = true;
            this.errorMessage = response.data.message;
          }
        })
        .catch((error) => {
          if (error.response.data.name) {
            this.error = true;
            this.errorMessage = error.response.data.name[0];
          } else if (error.response.data.email) {
            this.error = true;
            this.errorMessage = error.response.data.email[0];
          } else if (error.response.data.phone) {
            this.error = true;
            this.errorMessage = error.response.data.phone[0];
          } else if (error.response.data.reg_no) {
            this.error = true;
            this.errorMessage = error.response.data.reg_no[0];
          } else if (error.response.data.reference) {
            this.error = true;
            this.errorMessage = error.response.data.reference[0];
          } else if (error.response.data.sector) {
            this.error = true;
            this.errorMessage = error.response.data.sector[0];
          } else {
            this.error = true;
            this.errorMessage = "Something went wrong. Please try again.";
          }
        });
    },
    formValid() {
      return (
        this.name &&
        this.reg_no &&
        this.email &&
        this.phone &&
        this.reference &&
        this.sector
      );
    },
    submitForm() {
      if (this.formValid()) {
        // set loading
        this.$store.dispatch("setLoading", true);
        setTimeout(() => {
          this.error = false;
          this.errorMessage = "";
          this.performCreate().then(() => {
            // unset loading
            this.$store.dispatch("setLoading", false);
            if (this.error) {
              this.$buefy.toast.open({
                message: this.errorMessage,
                type: "is-danger",
              });
            } else {
              setTimeout(() => {
                this.$router.push({
                  name: "EmployerDashboard",
                });
              }, 2000);
            }
          });
        }, 1000);
      } else {
        this.error = true;
        this.errorMessage = "Please fill in all the required fields.";
        this.$buefy.toast.open({
          message: this.errorMessage,
          type: "is-danger",
        });
      }
    },
  },
  mounted() {
    this.$store.dispatch("setLoading", true);
    this.getReferenceOptions().then(() => {
      this.getSectorOptions().then(() => {
        this.$store.dispatch("setLoading", false);
      });
    });
  },
};
</script>

<style scoped>
.form-wrap {
  margin: 0 auto;
  margin-top: 5%;
}
</style>
